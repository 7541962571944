var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.flag),expression:"flag"}],staticClass:"house-footer"},[_c('div',{staticClass:"cson-container"},[_vm._m(0),_c('div',{staticClass:"code"},[(_vm.qr1)?_c('div',{staticClass:"code-con footer-code"},[_c('img',{staticClass:"code",attrs:{"src":_vm.qr1.imageUrl}}),_c('p',[_vm._v("- "+_vm._s(_vm.qr1.title)+" -")]),_c('div',{staticClass:"footer-bootom-code"},[_vm._v("实习应届最新职位消息")])]):_vm._e(),(_vm.qr2)?_c('div',{staticClass:"code-con footer-code"},[_c('img',{staticClass:"code",attrs:{"src":_vm.qr2.imageUrl}}),_c('p',[_vm._v("- "+_vm._s(_vm.qr2.title)+" -")]),_c('div',{staticClass:"footer-bootom-code"},[_vm._v("每周第一手求职信息")])]):_vm._e()]),_c('div',{staticClass:"link"},[_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',[_c('router-link',{attrs:{"to":{ path: '/question' }}},[_vm._v("帮助中心 ")])],1)]),_c('img',{attrs:{"src":require("@/assets/img/CSPIRATION.png")}})]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slogo"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo.png")}}),_c('p',[_vm._v("EMAIL：admin@cspiration.com")]),_c('h2',[_c('a',{attrs:{"href":"https://www.zhihu.com/people/ljs95","target":"_blank"}},[_c('img',{staticClass:"elselogo",attrs:{"src":require("@/assets/img/logo_zhihu.png")}})]),_c('a',{attrs:{"href":"https://space.bilibili.com/1863812604","target":"_blank"}},[_c('img',{staticClass:"elselogo",attrs:{"src":require("@/assets/img/logo_bilibili.png")}})]),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCTWuRL33U8xBPqk3LehXjFw","target":"_blank"}},[_c('img',{staticClass:"elselogo",attrs:{"src":require("@/assets/img/logo_youtube.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#/question?type=9"}},[_vm._v("隐私政策")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#/question?type=7"}},[_vm._v("用户协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#/question?type=8"}},[_vm._v("版权保护")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#/question?type=6"}},[_vm._v("网站安全")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('p',[_vm._v(" Copyright © 2018-2024 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v("浙ICP备19052770号")])])])
}]

export { render, staticRenderFns }