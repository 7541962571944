// 内推
export default [{
    path: '/referCenter',
    component: () =>
        import ('@/views/interpolation/center/index.vue'),
    name: 'referCenter',
    meta: {
        title: "内推主页 - 专注一线大厂算法面试 - CSON"
    },
    children: [{
        path: '/referCenter/list',
        name: 'list',
        meta: {
            title: "申请列表 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/interpolation/center/list.vue')
    }, {
        path: '/referCenter/myInterpolation',
        name: 'myInterpolation',
        meta: {
            title: "我的内推 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/interpolation/center/myInterpolation.vue')
    }, {
        path: '/referCenter/history',
        name: 'history',
        meta: {
            title: "历史内推 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/interpolation/center/history.vue')
    }, {
        path: '/referCenter/information',
        name: 'information',
        meta: {
            title: "我的信息 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/interpolation/center/information.vue')
    }]
}].map(a => {
    if (a.meta) {
        a.meta.requireAuth = true
    }
    if (a.children) {
        a.children.forEach(arr => {
            if (arr.meta) {
                arr.meta.requireAuth = true
            }

        })
    }
    return a
})