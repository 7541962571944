<template>
  <img
      v-if="avatar"
      :src="avatar"
      :style="{width: `${width}px`, height: `${height}px`}"
      style="border-radius: 50%; vertical-align: middle;cursor: pointer;" @click="showImformation()"
  />
  <div
      v-else
      :style="{width: `${width}px`, height: `${height}px`}"
      class="avatar-char"
  >{{ username }}
  </div>
</template>

<script>
export default {
  props: ["avatar", "username", "width", "height"],
  methods: {
    showImformation() {
      console.log(1)
      this.$router.push("/center/imformation");
    }
  }
};
</script>

<style lang="css" scoped>
.logo {
  border-radius: 50%;
}

.avatar-char {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 21px;
  color: rgb(253, 237, 225);
  background: rgb(246, 77, 5);
  margin: 10px auto;
}
</style>
