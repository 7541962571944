<template>
  <el-dialog :before-close="close" :visible.sync="dialogVisible" width="30%"  :close-on-click-modal="false">
    <div style="text-align: center">
      <template v-if="!leetcode">
        <div style="color: #000; margin-bottom: 10px">
          针对课程有疑问？
        </div>
        <div style="color: #fa6400; margin-bottom: 27px">
          扫描微信二维码课程和网站疑问
        </div>
      </template>
      <template v-else>
        <div style="color: #000; margin-bottom: 10px">添加微信</div>
        <div style="color: #fa6400; margin-bottom: 27px">
          即可获得LeetCode视频讲解视频
          <br />
          所有免费试看
        </div>
      </template>

      <img
        v-if="qr"
        :src="qr.imageUrl"
        alt=""
        style="width: 120px; margin-bottom: 30px"
      />
    </div>
  </el-dialog>
</template>
<script>
import { getCourseBanner } from "../../../service";
export default {
  name: "helpQrcode",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    leetcode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qr: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("setHelpVisible", val);
      },
    },
  },
  mounted() {
    
    getCourseBanner(3)
      .then((res) => {
        console.log(res);
        if (!res.result.code) {
          // this.banners = res.result;
          console.log(res.result);
          this.qr = res.result[0];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods:{
    close(){
      this.dialogVisible=false
    }
  }
};
</script>
<style scoped>
.confirm {
  width: 123px;
  height: 32px;
  padding: 0;
  background: #fa6400;
  border-radius: 2px;
  color: #fff;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog {
  border-radius: 4px;
}


</style>