<template>
<!-- 底部footer -->
  <div v-show="flag" class="house-footer">
    <div class="cson-container">
      <div class="slogo">
        <img class="logo" src="@/assets/img/logo.png" />
        <p>EMAIL：admin@cspiration.com</p>
        <h2>
          <a href="https://www.zhihu.com/people/ljs95" target="_blank">
            <img class="elselogo" src="@/assets/img/logo_zhihu.png" />
          </a>
          <a href="https://space.bilibili.com/1863812604" target="_blank">
            <img class="elselogo" src="@/assets/img/logo_bilibili.png" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCTWuRL33U8xBPqk3LehXjFw"
            target="_blank"
          >
            <img class="elselogo" src="@/assets/img/logo_youtube.png" />
          </a>
        </h2>
      </div>
      <div class="code">
        <div v-if="qr1" class="code-con footer-code">
          <img :src="qr1.imageUrl" class="code" />
          <p>- {{ qr1.title }} -</p>
          <div class="footer-bootom-code">实习应届最新职位消息</div>
        </div>
        <div v-if="qr2" class="code-con footer-code">
          <img :src="qr2.imageUrl" class="code" />
          <p>- {{ qr2.title }} -</p>
          <div class="footer-bootom-code">每周第一手求职信息</div>
        </div>
      </div>
      <div class="link">
        <ul>
          <li>
            <a href="#/question?type=9">隐私政策</a>
          </li>
          <li>
            <a href="#/question?type=7">用户协议</a>
          </li>
          <li>
            <a href="#/question?type=8">版权保护</a>
          </li>
          <li>
            <a href="#/question?type=6">网站安全</a>
          </li>
          <!-- <li>
            <router-link target="_blank" :to="{ path: '/harken' }"
              >倾听</router-link
            >
          </li> -->
          <li>
            <router-link :to="{ path: '/question' }">帮助中心 </router-link>
          </li>
        </ul>
        <img src="@/assets/img/CSPIRATION.png" />
      </div>
      <div class="about">
        <p>
          Copyright © 2018-2024
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >浙ICP备19052770号</a
          >
<!--          杭州世连华科技有限公司 电话：17342045430-->
<!--          地址：杭州余杭区仁和街道仁河大道88号4幢5楼2785室-->
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getCourseBanner } from "../../../service";

export default {
  data() {
    return {
      qr1: null,
      qr2: null,
      flag: true,
      path: "",
    };
  },
  created() {},
  watch: {
    $route(to) {
      this.path = to.path;
      console.log(this.path);
      const name = this.$route.name;

      if (name == null || name == undefined || name == "") {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
  },
  methods: {
    getCourseBanner() {
      console.log(this.path);
      const noHeaderList = [
        "/wechatRegister",
        "/wechatBindingEmail",
        "/registerform",
        "/editExpression",
      ];
      if (!noHeaderList.includes(this.path)) {
        getCourseBanner(2)
          .then((res) => {
            console.log(res);
            if (!res.result.code) {
              // this.banners = res.result;
              console.log(res.result);
              this.qr1 = res.result[0];
              this.qr2 = res.result[1];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    let _this = this;
    setTimeout(function () {
      _this.path = _this.$route.path;
      _this.getCourseBanner();
    }, 200);
  },
};
</script>
<style lang="less" scoped>
.elselogo {
  width: 24px !important;
  margin-right: 6px;
  vertical-align: middle;
}

.footer-bootom-code {
  margin-top: 5px;
  color: #999;
  font-size: 11px;
}

.footer-code {
  margin-left: 30px;
}

.footer-code p {
  margin-top: 10px;
}
</style>
