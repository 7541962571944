// 用户中心路由
export default [{
    path: '/answerCenter',
    component: () =>
        import ('@/views/answerCenter/index.vue'),
    name: 'answerCenter',
    meta: {
        title: "内推主页 - 专注一线大厂算法面试 - CSON"
    },
    children: [{
        path: '/answerCenter/list',
        name: 'list',
        meta: {
            title: "待回答题目 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/answerCenter/list.vue')
    }, {
        path: '/answerCenter/myQuestion',
        name: 'myQuestion',
        meta: {
            title: "我的题目 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/answerCenter/myQuestion.vue')
    }, {
        path: '/answerCenter/history',
        name: 'history',
        meta: {
            title: "提交记录 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/answerCenter/history.vue')
    }, {
        path: '/answerCenter/answerQuestion',
        name: 'answerQuestion',
        meta: {
            title: "回答题目 - 专注一线大厂算法面试 - CSON"
        },
        component: () =>
            import ('@/views/answerCenter/answerQuestion.vue')
    }]
}].map(a => {
    if (a.meta) {
        a.meta.requireAuth = true
    }
    if (a.children) {
        a.children.forEach(arr => {
            if (arr.meta) {
                arr.meta.requireAuth = true
            }

        })
    }
    return a
})