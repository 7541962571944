<template>
  <div class="leet-popup">
	<transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
		<div class="ball" v-show="flag"></div>
	</transition>
	  <div class="zxt-bar">
		  <div class="lc-top" @click="dialogVisible = true">
		  	LC千题<br />
		  	免费领取
		  </div>
	    <div class="zxt-box">
	      <i class="iconfont icon-liwu"></i>
	      <p>课程咨询</p>
	      <!-- piao -->
	      <div class="zxt-popover">
	        <img
	          v-if="qr"
	          :src="qr.imageUrl"
	          alt=""
	          style="width: 100px; height: 100px"
	        />
	        <div class="zxt-body">
	          <div class="t">
	            添加客服微信<br />
	            即可免费咨询<br />
	          </div>
	        </div>
	      </div>
	    </div>
	    <router-link to="/activity-ask" class="zxt-box">
	      <i class="iconfont icon-houtou"></i>
	      <p>免费选校</p>
	    </router-link>
	  </div>
	  <el-dialog class="leetDialogVisible" :visible.sync="dialogVisible" width="770px" append-to-body :close-on-click-modal="false" @closed="leetDialogClose">
	    <div class="leet-dialog">
	  		<div class="leet-photo">
	  			<img src="@/assets/images/beimei/leet-pic.png" alt="" />
	  		</div>
	  		<div class="leet-grid">
	  			<div class="leet-title">《LeetCode千题视频讲解》免费发放中</div>
	  			<div class="leet-desc">刷题必备的《LeetCode千题视频讲解》 <br>限时免费开通，先到先得，不能错过</div>
<!--	  			<div class="leet-link">-->
<!--	  				<el-link @click="url('/course/13')" type="primary">戳此了解兑换流程 >></el-link>-->
<!--	  			</div>-->
	  			<div class="leet-button">
	  				<el-button @click="url('/course/13')" type="primary">点击参与活动</el-button>
	  			</div>
	  		</div>
	  	</div>
	  </el-dialog>
  </div>
</template>
<script>
import { getCourseBanner } from "../service";

export default {
  data() {
	return {
		qr: null,
		dialogVisible:false,
		flag: false,
	};
  },
created() {
  this.checkShowDialog();
},
  methods: {
	checkShowDialog() {
		const showLeetDialog = localStorage.getItem('showLeetDialog');
		if (showLeetDialog === null || showLeetDialog === 'true') {
			localStorage.setItem('showLeetDialog', 'true');
			this.dialogVisible = localStorage.getItem('showLeetDialog');
		}
	},
	url(url){
		this.$router.push(url)
	},
	beforeEnter(el) {
		el.style.transform = "translate(0,0)";
	},
	enter(el, done) {
		el.offsetWidth; // 这句话没有实际的作用，如果不写，浏览器就报错
		el.style.transform = "translate(650px,80px)"; // 控制位置
		el.style.transition = "all 1s ease";
		done(); // 记得 afterEnter调用
	},
	afterEnter(el) {
		this.flag = !this.flag;
	},
	leetDialogClose(){
		this.flag = !this.flag;
		localStorage.setItem('showLeetDialog', 'false');
	},
	getCourseBanner() {
	  getCourseBanner(3)
		.then((res) => {
		  console.log(res);
		  if (!res.result.code) {
			// this.banners = res.result;
			console.log(res.result);
			this.qr = res.result[0];
		  }
		})
		.catch((err) => {
		  console.log(err);
		});
	},
  },
  mounted() {
	this.getCourseBanner();
  },
};

</script>
<style scoped lang="scss">
	.ball {
		position: fixed;
		left: 50%;
		top: 50%;
		z-index: 222;
	    width: 40px;
	    height: 40px;
	    background: linear-gradient(90deg, #dc433f 0%, #eeb07b 100%);
	    border-radius: 50%;
	}
	
	.go_shoppingcar {
	    width: 170px;
	    height: 40px;
	    line-height: 40px;
	    text-align: center;
	    border: 1px solid #000;
	    cursor: pointer;
	}
	
	.buynow {
	    width: 170px;
	    height: 40px;
	    line-height: 40px;
	    background-color: #c10000;
	    color: #fff;
	    text-align: center;
	    cursor: pointer;
	}
	.leetDialogVisible ::v-deep .el-dialog{
		border-radius: 10px;
	}
	.leetDialogVisible ::v-deep .el-dialog__body{
		padding: 0;
	}
	.leetDialogVisible ::v-deep .el-dialog__header{
		padding: 0;
	}
	.leetDialogVisible ::v-deep .el-dialog__headerbtn .el-dialog__close{
		color: #fff;
		font-size: 20px;
	}
	.leet-grid{
		position: relative;
		padding: 40px 50px;
		.leet-title{
			font-size: 24px;
			margin-bottom: 10px;
		}
		.leet-desc{
			line-height: 24px;
			margin-bottom: 20px;
			font-size: 16px;
		}
	}
	.leet-button{
		position: absolute;
		right: 50px;
		bottom: 60px;
	}
	
	.zxt-bar {
	  width: 70px;
	  background: #ffffff;
	  border-radius: 6px;
	  text-align: center;
	  background: #ffffff;
	  box-shadow: 0px 0px 4px 2px rgba(250, 100, 0, 0.1);
	  line-height: 40px;
	  position: fixed;
	  bottom: 20%;
	  left: 50%;
	  margin-left: 500px;
	  cursor: pointer;
	  z-index: 1499;
	}
	
	.zxt-box {
		display: block;
	  position: relative;
	  height: 70px;
	  line-height: 1;
	  box-sizing: border-box;
	  padding: 12px 0;
	}
	
	.zxt-box p {
	  padding-top: 2px;
	}
	
	.zxt-box {
	  border-bottom: 1px solid #eeeeee;
	}
	
	.zxt-box p,
	.zxt-box .iconfont {
	  color: #fa6400;
	  line-height: 21px;
	  font-weight: 500;
	  background: linear-gradient(133deg, #f10603 0%, #f7f5c9 100%);
	  background-clip: text;
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
	}
	.zxt-box:last-child{
		border-bottom: 0;
	}
	.zxt-box:last-child p,
	.zxt-box:last-child .iconfont {
	  font-weight: 500;
	  color: #fa6400;
	  line-height: 21px;
	  background: linear-gradient(116deg, #ff4e50 0%, #f9d423 100%);
	  background-clip: text;
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
	}
	.zxt-box p{
		font-weight: bold;
		padding-top: 5px;
	}
	.zxt-bar .iconfont {
	  font-size: 24px;
	}
	
	@media (min-width: 1600px) {
	  .zxt-bar {
	    margin-left: 640px;
	  }
	}
	
	@media (max-width: 1600px) {
	  .zxt-bar {
	    margin-left: 650px;
	  }
	}
	
	.zxt-box .t {
	  line-height: 16px;
	  color: #fa6400 !important;
	  font-size: 12px;
	}
	
	.zxt-box .g {
	  line-height: 16px;
	  color: #999 !important;
	  font-size: 12px;
	  margin-top: 10px;
	}
	
	.zxt-popover {
	  position: absolute;
	  width: 240px;
	  z-index: 99;
	  right: 80px;
	  top: 0px;
	  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	  border-radius: 12px;
	  background-color: #fff;
	  display: none;
	  position: absolute;
	  display: none;
	  align-items: center;
	  text-align: left;
	  padding: 20px;
	}
	
	.zxt-popover img {
	  display: block;
	  margin-right: 10px;
	}
	
	.zxt-popover .zxt-body {
	  flex: 1;
	}
	
	.zxt-popover::after {
	  position: absolute;
	  display: block;
	  width: 0;
	  height: 0;
	  background-color: #ffffff;
	  top: 30%;
	  width: 12px;
	  height: 12px;
	  right: -4px;
	  transform: rotateZ(45deg);
	  content: "";
	}
	
	.zxt-box:hover .zxt-popover {
	  display: flex;
	}
	.lc-top{
		padding:15px 5px;
		text-align: center;
		font-weight: bold;
		border-radius: 6px 6px 0 0;
		color: #fff;
		line-height: 1.4;
		background: linear-gradient(90deg, #dc433f 0%, #eeb07b 100%);
	}
	
</style>