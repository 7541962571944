<template>
  <!--通用头部-->
  <div
    v-show="flag"
    :class="[
      'house-header',
      showFixedAct ? 'hasAct' : '',
      isBgGrey() ? 'grey-header' : '',
    ]"
  >
    <div class="cson-container">
      <div class="house-nav">
        <img
          class="logo"
          src="@/assets/img/logowhite.png"
          @click="goIndex"
          style="cursor: pointer"
        />
        <template>
          <span v-if="!userInfo.id" class="layui-breadcrumb visibility-unset">
            <!-- <router-link to="/audition">
              <a class="testlisten">免费试听</a>
            </router-link> -->
            <!-- <router-link to="/registerform">
              <a class="link">注册</a>
            </router-link>
            <a class="margin-left-right10 line">|</a> -->
            <span
              style="color: #fff; font-size: 16px; cursor: pointer"
              class="link"
              @click="loginDialogVisible = true"
              >登陆 / 注册</span
            >
            <LoginDialog
              :visible="loginDialogVisible"
              @changeVisible="(val) => (loginDialogVisible = val)"
            ></LoginDialog>
          </span>
          <div v-else class="haslogin layui-breadcrumb">
            <!-- <router-link to="/audition">
              <a class="testlisten">免费试听</a>
            </router-link> -->
            <!-- 购物车 -->
            <!-- <el-badge :value="12" class="item">
              <router-link to="/cart">
                <img class="icon" src="@/assets/img/icon-cart.png" />
              </router-link>
            </el-badge>-->
            <!-- 消息 -->
            <el-badge
              :value="unreadCounts"
              :hidden="unreadCounts == 0"
              class="item"
              :max="10"
            >
              <el-popover
                class="message-popover"
                placement="bottom"
                trigger="click"
                width="380"
                v-model="popoverVisible"
              >
                <div class="messages">
                  <el-tabs
                    v-model="activeTab"
                    @tab-click="handleChangeTab"
                    stretch
                  >
                    <el-tab-pane label="系统通知" name="sysNotice">
                      <span slot="label">
                        <el-badge
                          :value="systemCount"
                          :hidden="systemCount == 0"
                          class="item"
                          :max="10"
                        >
                          <i class="icon iconfont icon-youjian"></i>
                        </el-badge>
                      </span>
                      <div class="messageContainer" v-infinite-scroll="load">
                        <div
                          v-for="(item, i) in sysNoticeList"
                          :key="i"
                          :class="[
                            item.readStatus ? 'message read' : 'message',
                          ]"
                        >
                          <div class="message-top">
                            <div
                              v-if="item.type"
                              :class="[
                                `message-top-label-${item.type.value}`,
                                'message-top-left',
                              ]"
                              class="message-top-left"
                            >
                              {{ item.type.name }}
                            </div>
                            <div class="message-top-right">
                              {{ item.createTime }}
                            </div>
                          </div>
                          <div
                            class="message-content"
                            v-html="item.content"
                          ></div>
                        </div>
                        <div
                          v-if="sysNoticeList.length == 0"
                          style="
                            padding: 30px;
                            text-align: center;
                            font-size: 18px;
                          "
                        >
                          暂无通知
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="评论通知" name="commentNotice">
                      <span slot="label">
                        <el-badge
                          :value="commentCount"
                          :hidden="commentCount == 0"
                          class="item"
                          :max="10"
                        >
                          <i class="icon iconfont icon-pinglun1"></i></el-badge
                      ></span>
                      <div class="messageContainer" v-infinite-scroll="load">
                        <div
                          v-for="(item, i) in commentNoticeList"
                          :key="i"
                          :class="[
                            item.readStatus ? 'message read' : 'message',
                          ]"
                        >
                          <div class="message-top">
                            <div
                              v-if="item.type"
                              :class="[
                                `message-top-label-${item.type.value}`,
                                'message-top-left',
                              ]"
                            >
                              {{ item.type.name }}
                            </div>
                            <div class="message-top-right">
                              {{ item.createTime }}
                            </div>
                          </div>
                          <div
                            class="message-content"
                            v-html="item.content"
                          ></div>
                        </div>
                      </div>
                      <div
                        v-if="commentNoticeList.length == 0"
                        style="
                          padding: 30px;
                          text-align: center;
                          font-size: 18px;
                        "
                      >
                        暂无通知
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="点赞通知" name="likeNotice">
                      <span slot="label">
                        <el-badge
                          :value="lickCount"
                          :hidden="lickCount == 0"
                          class="item"
                          :max="10"
                        >
                          <i
                            class="icon iconfont icon-a-xihuanlike"
                          ></i> </el-badge
                      ></span>
                      <div class="messageContainer" v-infinite-scroll="load">
                        <div
                          v-for="(item, i) in likeNoticeList"
                          :key="i"
                          :class="[
                            item.readStatus ? 'message read' : 'message',
                          ]"
                        >
                          <div class="message-top">
                            <div
                              v-if="item.type"
                              :class="[
                                `message-top-label-${item.type.value}`,
                                'message-top-left',
                              ]"
                            >
                              {{ item.type.name }}
                            </div>
                            <div class="message-top-right">
                              {{ item.createTime }}
                            </div>
                          </div>
                          <div
                            class="message-content"
                            v-html="item.content"
                          ></div>
                        </div>
                      </div>
                      <div
                        v-if="likeNoticeList.length == 0"
                        style="
                          padding: 30px;
                          text-align: center;
                          font-size: 18px;
                        "
                      >
                        暂无通知
                      </div>
                    </el-tab-pane>
                  </el-tabs>

                  <div class="message-footer" @click="onJumpToMessageBox">
                    查看全部
                  </div>
                </div>
                <div slot="reference" style="cursor: pointer">
                  <img class="icon" src="@/assets/img/icon-message.png" />
                </div>
              </el-popover>
            </el-badge>
            <!-- 头像 -->
            <el-popover
              class="message-popover"
              placement="top-start"
              trigger="hover"
              width="200"
            >
              <div class="userpopup">
                <div class="userpopup-content">
                  <Avatar
                    :avatar="userInfo.avatar"
                    :username="userInfo.username"
                    height="40"
                    width="40"
                  />
                  <div class="userpopup-info">
                    <p>{{ userInfo.username || "" }}</p>
                    <!-- <div>{{userInfo.introduction || ''}}</div> -->
                  </div>
                </div>
                <div class="userpopup-actions">
                  <router-link to="/center/mylesson">
                    <img
                      src="@/assets/img/pop-1.png"
                      style="width: 14px; height: 18px"
                    />
                    <span>我的课程</span>
                  </router-link>
                  <router-link to="/center/imformation">
                    <img
                      src="@/assets/img/pop-2.png"
                      style="width: 20px; height: 18px"
                    />
                    <span>个人设置</span>
                  </router-link>
                  <router-link to="/center/order">
                    <img
                      src="@/assets/img/pop-3.png"
                      style="width: 13px; height: 20px"
                    />
                    <span>我的订单</span>
                  </router-link>
                  <router-link to="/center/certificate">
                    <img
                      src="@/assets/img/pop-4.png"
                      style="width: 22px; height: 16px"
                    />
                    <span>实名认证</span>
                  </router-link>
                </div>
                <div class="userpopup-exit" @click="onExit">退出登录</div>
              </div>
              <router-link slot="reference" to="/center/mylesson">
                <Avatar
                  :avatar="userInfo.avatar"
                  :username="userInfo.username"
                  height="32"
                  width="32"
                  style="transform: translateY(5px)"
                />
              </router-link>
            </el-popover>
          </div>
        </template>
        <ul class="layui-nav close">
          <template v-for="(item, index) in navList">
            <li
              v-if="!item.tip"
              :key="index"
              :class="item.checked ? 'active' : ''"
              @click="tabNav(index, item)"
            >
              <span>{{ item.name }}</span>
              <i v-if="item.children" class="el-icon-caret-bottom"></i>
              <ul class="childUl">
                <li
                  v-for="(child, ii) in item.children"
                  :key="ii"
                  @click="tabNav(ii, child)"
                >
                  {{ child.name }}
                </li>
              </ul>
            </li>
            <li
              v-else
              :key="index"
              :class="item.checked ? 'active' : ''"
              @click="tabNav(index, item)"
            >
              <el-badge :value="item.tip" class="item">
                <span>{{ item.name }}</span>
                <i v-if="item.children" class="el-icon-caret-bottom"></i>
                <ul class="childUl">
                  <li
                    v-for="(child, ii) in item.children"
                    :key="ii"
                    @click="tabNav(ii, child)"
                  >
                    {{ child.name }}
                  </li>
                </ul>
              </el-badge>
            </li>
          </template>
        </ul>

        <!--        <ul v-if="userInfo && userInfo.id" class="layui-nav close right-user">-->
        <!--          <li-->
        <!--            v-if="userCourse.hasMock"-->
        <!--            @click="$router.push('/center/mockInterview')"-->
        <!--          >-->
        <!--            <span>模拟面试</span>-->
        <!--          </li>-->
        <!--          <li-->
        <!--            v-if="userCourse.hasCourse"-->
        <!--            @click="$router.push('/center/mylesson')"-->
        <!--          >-->
        <!--            <span>我的课程</span>-->
        <!--          </li>-->
        <!--        </ul>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  queryMessageList,
  readNotices,
  checkUserBuyCourse,
} from "../../../service/index";
// import { getIn } from "../../../utils/index";
// import { Loading } from "element-ui";
import Avatar from "../../../components/avatar";
import LoginDialog from "../../1_user/login/loginDialog.vue";
export default {
  props: {
    showFixedAct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flag: true,
      path: "",
      loginDialogVisible: false,
      navList: [
        // {
        //   path: "/",
        //   name: "首页",
        //   checked: true,
        // },
        // {
        //   path: "/VIPadvertise",
        //   name: "2023秋招",
        //   checked: false,
        // },
        {
          path: "/haioffer",
          name: "北美职位",
          checked: false,
        },
        // {
        //   path: "/course",
        //   name: "求职课",
        //   checked: false,
        // },
        {
          name: "北美求职",
          checked: false,
          children: [
            {
              path: "/course/11",
              name: "北美求职必修课",
              checked: false,
            },
            {
              path: "/course/2",
              name: "LeetCode千题讲解",
              checked: false,
            },
            {
              path: "/course/7",
              name: "北美SDE求职旗舰班",
              checked: false,
            },
            {
              path: "/course/12",
              name: "北美SDE项⽬速成班",
              checked: false,
            },
          ],
        },
        {
          path: "/studyAbroadApply",
          name: "留学申请",
          checked: false,
        },
        {
          name: "免费资源",
          checked: false,
          children: [
            {
              path: "/course/13",
              name: "LeetCode千题讲解（免费版）",
              checked: false,
            },
            {
              path: "/leetcodeClassification",
              name: "LeetCode分类顺序表",
              checked: false,
            },
            {
              path: "/course/11",
              name: "北美求职通识课",
              checked: false,
            },
          ],
        },
        // {
        //   name: "留学申请",
        //   checked: false,
        //   children: [
        //     {
        //       path: "/activity-ask",
        //       name: "免费选校",
        //       checked: false,
        //     },
        //     {
        //       path: "/studyAbroadApply",
        //       name: "留学申请服务",
        //       checked: false,
        //     },
        //   ],
        // },
        {
          name: "其他",
          checked: false,
          children: [
            {
              path: "/story",
              name: "创始人故事",
              checked: false,
            },
            {
              path: "/question",
              name: "帮助中心",
              checked: false,
            },
          ],
        },
        // {
        //   name: "LeetCode",
        //   checked: false,
        //   children: [
        //     {
        //       path: "/leetcodeClassification", //leetcodeClassification
        //       name: "LeetCode分类顺序表",
        //       checked: false,
        //     },
        //     {
        //       path: "/course/2",
        //       name: "LeetCode千题视频讲解",
        //       checked: false,
        //     },
        //   ],
        // },
        // {
        //   path: "/liveRoom",
        //   name: "面经直播",
        //   checked: false,
        // },
        // {
        //       path: "/audition",
        //       name: "免费视频",
        //       checked: false,
        // },

        // {
        //   path: "/activityFreeCourse",
        //   name: "活动",
        //   checked: false,
        //   tip: "优惠",
        // },
        // {
        //   path: "/refer",
        //   name: "北美内推",
        //   checked: false,
        // },

        // {
        //   name: "北美求职",
        //   checked: false,
        //   children: [
        //     {
        //       path: "/audition",
        //       name: "求职视频",
        //       checked: false,
        //     },
        //     {
        //       path: "/interviewExpression",
        //       name: "经验感悟",
        //       checked: false,
        //     },
        //   ],
        // },
        // {
        //   name: "其他",
        //   checked: false,
        //   children: [
        //     {
        //       path: "/story",
        //       name: "创始人故事",
        //       checked: false,
        //     },
        //     {
        //       path: "/question",
        //       name: "帮助中心",
        //       checked: false,
        //     },
        //     {
        //       path: "/harken",
        //       name: "倾听",
        //       checked: false,
        //     },
        //   ],
        // },
      ],
      noticelist: [],
      hidNotice: false,
      sysNoticeList: [],
      commentNoticeList: [],
      likeNoticeList: [],
      sysCp: 1,
      commentCp: 1,
      likeCp: 1,
      sysTotal: 0,
      commentTotal: 0,
      likeTotal: 0,
      activeTab: "sysNotice",
      popoverVisible: false,
      unreadCounts: 0,
      intervalTimeout: null,
      commentCount: 0,
      lickCount: 0,
      systemCount: 0,
      userCourse: {
        hasCourse: false,
        hasMock: false,
      },
    };
  },
  components: {
    Avatar,
    LoginDialog,
  },
  computed: mapState(["userInfo"]),
  mounted() {
    this.path = this.$route.path;
    // this.checkUserBuyCourse();
  },
  created() {},
  watch: {
    $route(to) {
      this.path = to.path;

      console.log(this.$route);
      const name = this.$route.name;

      if (name == null || name == undefined || name == "") {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    userInfo() {
      if (this.userInfo.id) {
        this.getSysNoticeList();
      }
    },
    async popoverVisible(val) {
      if (val) {
        this.intervalTimeout = setInterval(() => {
          var unReadNotice = [];
          switch (this.activeTab) {
            case "sysNotice":
              unReadNotice = this.sysNoticeList
                .filter((item) => {
                  return item.readStatus == false;
                })
                .map((item) => {
                  return item.id;
                });
              if (unReadNotice.length == 0) {
                return;
              }
              readNotices(unReadNotice).then(async (res) => {
                this.sysNoticeList
                  .filter((item) => {
                    return item.readStatus == false;
                  })
                  .map((item) => {
                    item.readStatus = true;
                  });
                const resCount = await queryMessageList({
                  current: 1,
                  size: 10,
                });
                this.unreadCounts = resCount.result.count;
                this.commentCount = resCount.result.commentCount;
                this.lickCount = resCount.result.lickCount;
                this.systemCount = resCount.result.systemCount;
              });
              break;
            case "commentNotice":
              unReadNotice = this.commentNoticeList
                .filter((item) => {
                  return item.readStatus == false;
                })
                .map((item) => {
                  return item.id;
                });
              if (unReadNotice.length == 0) {
                return;
              }
              readNotices(unReadNotice).then(async (res) => {
                this.commentNoticeList
                  .filter((item) => {
                    return item.readStatus == false;
                  })
                  .map((item) => {
                    item.readStatus = true;
                  });
                const resCount = await queryMessageList({
                  current: 1,
                  size: 10,
                });
                this.unreadCounts = resCount.result.count;
                this.commentCount = resCount.result.commentCount;
                this.lickCount = resCount.result.lickCount;
                this.systemCount = resCount.result.systemCount;
              });
              break;
            case "likeNotice":
              unReadNotice = this.likeNoticeList
                .filter((item) => {
                  return item.readStatus == false;
                })
                .map((item) => {
                  return item.id;
                });
              if (unReadNotice.length == 0) {
                return;
              }
              readNotices(unReadNotice).then(async (res) => {
                this.likeNoticeList
                  .filter((item) => {
                    return item.readStatus == false;
                  })
                  .map((item) => {
                    item.readStatus = true;
                  });
                const resCount = await queryMessageList({
                  current: 1,
                  size: 10,
                });
                this.unreadCounts = resCount.result.count;
                this.commentCount = resCount.result.commentCount;
                this.lickCount = resCount.result.lickCount;
                this.systemCount = resCount.result.systemCount;
              });
              break;
          }
        }, 1000);
      } else {
        clearInterval(this.intervalTimeout);
      }
    },
  },
  methods: {
    onExit() {
      window.localStorage.setItem("CSON_PAGE_TOKEN", "");
      window.location.href = "#/";
      window.location.reload();
    },
    async getSysNoticeList(page) {
      if (page) {
        this.sysCp = page;
      } else {
        this.sysCp = 1;
      }
      const res = await queryMessageList({
        current: page ? page : 1,
        size: 10,
        type: "0",
      });
      this.sysNoticeList = res.result.page.records;
      this.sysTotal = res.result.page.total;
      this.unreadCounts = res.result.count;
      this.commentCount = res.result.commentCount;
      this.lickCount = res.result.lickCount;
      this.systemCount = res.result.systemCount;
    },
    async getCommentNoticeList(page) {
      if (page) {
        this.commentCp = page;
      } else {
        this.commentCp = 1;
      }
      const res = await queryMessageList({
        current: page ? page : 1,
        size: 10,
        type: "1",
      });
      this.commentNoticeList = res.result.page.records;
      this.commentTotal = res.result.page.total;
    },
    async getLikeNoticeList(page) {
      if (page) {
        this.likeCp = page;
      } else {
        this.likeCp = 1;
      }
      const res = await queryMessageList({
        current: page ? page : 1,
        size: 10,
        type: "2",
      });
      this.likeNoticeList = res.result.page.records;
      this.likeTotal = res.result.page.total;
    },
    handleChangeTab(tab) {
      clearInterval(this.intervalTimeout);
      this.intervalTimeout = setInterval(() => {
        var unReadNotice = [];
        switch (this.activeTab) {
          case "sysNotice":
            unReadNotice = this.sysNoticeList
              .filter((item) => {
                return item.readStatus == false;
              })
              .map((item) => {
                return item.id;
              });
            if (unReadNotice.length == 0) {
              return;
            }
            readNotices(unReadNotice).then(async (res) => {
              this.sysNoticeList
                .filter((item) => {
                  return item.readStatus == false;
                })
                .map((item) => {
                  item.readStatus = true;
                });
              const resCount = await queryMessageList({
                current: 1,
                size: 10,
              });
              this.unreadCounts = resCount.result.count;
              this.commentCount = resCount.result.commentCount;
              this.lickCount = resCount.result.lickCount;
              this.systemCount = resCount.result.systemCount;
            });
            break;
          case "commentNotice":
            unReadNotice = this.commentNoticeList
              .filter((item) => {
                return item.readStatus == false;
              })
              .map((item) => {
                return item.id;
              });
            if (unReadNotice.length == 0) {
              return;
            }
            readNotices(unReadNotice).then(async (res) => {
              this.commentNoticeList
                .filter((item) => {
                  return item.readStatus == false;
                })
                .map((item) => {
                  item.readStatus = true;
                });
              const resCount = await queryMessageList({
                current: 1,
                size: 10,
              });
              this.unreadCounts = resCount.result.count;
              this.commentCount = resCount.result.commentCount;
              this.lickCount = resCount.result.lickCount;
              this.systemCount = resCount.result.systemCount;
            });
            break;
          case "likeNotice":
            unReadNotice = this.likeNoticeList
              .filter((item) => {
                return item.readStatus == false;
              })
              .map((item) => {
                return item.id;
              });
            if (unReadNotice.length == 0) {
              return;
            }
            readNotices(unReadNotice).then(async (res) => {
              this.likeNoticeList
                .filter((item) => {
                  return item.readStatus == false;
                })
                .map((item) => {
                  item.readStatus = true;
                });
              const resCount = await queryMessageList({
                current: 1,
                size: 10,
              });
              this.unreadCounts = resCount.result.count;
              this.commentCount = resCount.result.commentCount;
              this.lickCount = resCount.result.lickCount;
              this.systemCount = resCount.result.systemCount;
            });
            break;
        }
      }, 1000);
      switch (tab.name) {
        case "sysNotice":
          this.getSysNoticeList();
          break;
        case "commentNotice":
          this.getCommentNoticeList();
          break;
        case "likeNotice":
          this.getLikeNoticeList();
          break;
      }
    },
    async load() {
      switch (this.activeTab) {
        case "sysNotice":
          if (this.sysNoticeList.length >= this.sysTotal) {
            return;
          }
          var res = await queryMessageList({
            current: this.sysCp + 1,
            size: 10,
            type: "0",
          });
          this.sysNoticeList = this.sysNoticeList.concat(
            res.result.page.records
          );
          this.sysCp = this.sysCp + 1;
          break;
        case "commentNotice":
          if (this.commentNoticeList.length >= this.commentTotal) {
            return;
          }
          var res = await queryMessageList({
            current: this.commentCp + 1,
            size: 10,
            type: "1",
          });
          this.commentNoticeList = this.commentNoticeList.concat(
            res.result.page.records
          );
          this.commentCp = this.commentCp + 1;
          break;
        case "likeNotice":
          if (this.likeNoticeList.length >= this.likeTotal) {
            return;
          }
          var res = await queryMessageList({
            current: this.likeCp + 1,
            size: 10,
            type: "2",
          });
          this.likeNoticeList = this.likeNoticeList.concat(
            res.result.page.records
          );
          this.likeCp = this.likeCp + 1;
          break;
      }
    },
    setCenterHeader() {
      // const noneCenterHeaderList = [
      //   "/center",
      //   "/cart",
      //   "confirm",
      //   "/lessoncenter"
      // ];
      // const i = noneCenterHeaderList.findIndex(v => {
      //   return this.path.includes(v);
      // });
      // return i > -1;
      return false;
    },
    isBgGrey() {
      // 个人信息页面头部信息背景图
      const greyHeaderList = [
        "/harken",
        "/story",
        "/audition",
        "/auditionWatch",
        "/course",
        "/refer",
        "/editExpression",
        "/interviewExpDetail",
        "/harkenDetail",
        "/center/imformation",
        "/center/account",
        "/center/coupon",
        "/center/certificate",
        "/center/order",
        "/center/mylesson",
        "/center/comments",
        "/center/subscription",
        "/center/myComments",
        "/center/myResume",
        "/center/accessList",
        "/center/referInvite",
        "/center/mockInterview",
        "/lessoncenter",
        "/question",
        "/confirm",
        "/orderpay",
        "/message",
        "/pay/success",
        "/pay/failure",
        "/punchClock",
        "/punchClockInit",
        "/myGroup",
        "/activityDetail",
        "/interviewExperience",
        "/interviewDetail",
        "/interviewEdit",
        "/company",
        "/companyDetail",
        "/questionDetail",
        "/interviewPlan",
        "/live",
        "/liveRoom",
        "/interviewExpression",
        "/provideResource",
        "/trends",
        "/companyList",
        "/userPage",
        "/subscribe",
        "/subscribe/choosePlan",
        "/research",
        "/interpolation",
        "/leetcode",
        "/leetcodeClassification",
        "/leetcodeClassification/discussion",
        "/leetcodeClassification/codeDetail",
        "/leetcodeClassification/preferResolution",
        "/leetcodeClassification/preferIdea",
        "/mock/1",
        "/mock/2",
        "/mock/3",
        "/mock/4",
        "/mock/5",
      ];
      return greyHeaderList.includes(this.path);
    },
    onJumpToMessageBox() {
      this.$router.push("/message");
    },
    tabNav(index, item) {
      this.navList.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.$router.push(item.path);
    },
    goIndex() {
      if (this.userInfo && this.userInfo.id) {
        this.$router.push("/");
      } else {
        this.$router.push("/");
      }
    },
    checkUserBuyCourse() {
      checkUserBuyCourse().then((res) => {
        if (res.success) {
          this.userCourse = res.result;
        }
      });
    },
    readNotices(item) {
      readNotices([item.id]).then((res) => {
        if (res.success) {
          item.readStatus = true;
          this.$forceUpdate();
        }
      });
    },
  },
};
</script>
<style lang="css" scoped>
::v-deep .item .el-badge__content.is-fixed {
  border: 1px solid transparent;
  transform: translateY(-20%) translateX(100%);
}
.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 4px;
}

.visibility-unset {
  display: flex;
  align-items: center;
}

.router-link-active {
  /* display: inline-flex; */
  display: inline;
  align-items: center;
  justify-content: center;
}

.messages {
  width: 380px;
  box-sizing: border-box;
  padding: 14px 0;
  padding-top: 0px;
}

.message-title {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 21px;
  border-bottom: 1px dashed #979797;
  box-sizing: border-box;
  padding: 0 26px;
  padding-bottom: 12px;
}

.message {
  box-sizing: border-box;
  padding: 12px 26px;
  transition: all linear 0.2s;
}
.message.read .message-content {
  color: #999999;
}

.message:hover {
  background: rgba(235, 236, 241, 0.43);
}

.message-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-top-left {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 10px;
  height: 18px;
  line-height: 18px;
  background: #f78d00;
  border-radius: 10px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}

.message-top-right {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.message-content {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 10px;
}

.message-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #fa6400;
  line-height: 18px;
  text-decoration: underline;
  border-top: 1px dashed #979797;
  box-sizing: border-box;
  padding-top: 14px;
}

.actions {
  display: inline-flex;
  align-items: center;
}

.userpopup {
  box-sizing: border-box;
  padding: 12px 12px;
  transition: all linear 0.2s;
}

.userpopup-content {
  display: flex;
  align-items: center;
}

.userpopup-content-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.userpopup-info {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.userpopup-info p {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
}

.userpopup-info div {
  font-size: 12px;
  font-family: SourceHanSansCN-ExtraLight, SourceHanSansCN;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.85);
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 140px;
}

.userpopup-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 13px;
  padding-bottom: 8px;
  box-sizing: border-box;
  border-bottom: 1px dashed #979797;
}

.userpopup-actions > a {
  width: 80px;
  height: 28px;
  background: rgba(235, 236, 241, 0.5);
  border-radius: 2px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding-left: 4px !important;
  padding-right: 4px !important;
  box-sizing: border-box;
  justify-content: flex-start;
}

.userpopup-actions > a > span {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #000000;
  margin-left: 2px;
}

.userpopup-exit {
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #fa6400;
  border: 1px solid #fa6400;
  border-radius: 2px;
  margin: 0 auto;
  margin-top: 15px;
  cursor: pointer;
}
</style>
<style>
.el-popover {
  padding: 0 !important;
}
.messageContainer {
  max-height: 300px;
  overflow-y: auto;
}
.messageContainer::-webkit-scrollbar {
  width: 0 !important;
}
.el-tabs__header {
  margin-bottom: 0px !important;
}

.item .el-badge__content.is-fixed {
  top: 8px !important;
  right: 0px !important;
}
.message-top-label-0 {
  background: rgba(0, 145, 255, 1) !important;
}
.message-top-label-1 {
  background: rgba(247, 141, 0, 1) !important;
}
.message-top-label-3 {
  background: rgba(222, 19, 6, 1) !important;
}
.hasAct:not(.grey-header) {
  top: 100px !important;
}
.right-user {
  float: right;
}
</style>
