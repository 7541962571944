// 会员中心
export default [{
    path: '/center',
    component: () =>
        import ('@/views/center/index.vue'),
    children: [
        // 会员中心首页
        {
            path: '/center/order',
            name: 'order',
            meta: {
                title: "我的订单 - CSON"
            },
            component: () =>
                import ('@/views/center/order/list.vue')
        },
        {
            path: '/center/subscription',
            name: 'subscription',
            meta: {
                title: "我的订阅 - CSON"
            },
            component: () =>
                import ('@/views/center/subscription/index.vue')
        },
        {
            path: '/center/mylesson',
            name: 'mylesson',
            meta: {
                title: "我的课程 - CSON"
            },
            component: () =>
                import ('@/views/center/mylesson/list.vue')
        },
        {
            path: '/center/comments',
            name: 'comments',
            meta: {
                title: "班课评价 - CSON"
            },
            component: () =>
                import ('@/views/center/comment/comments.vue')
        },
        {
            path: '/center/account',
            name: 'account',
            meta: {
                title: "我的账户 - CSON"
            },
            component: () =>
                import ('@/views/center/account/account.vue')
        },
        {
            path: '/center/coupon',
            name: 'coupon',
            meta: {
                title: "我的卡卷 - CSON"
            },
            component: () =>
                import ('@/views/center/coupon/coupon.vue')
        },
        {
            path: '/center/imformation',
            name: 'imformation',
            meta: {
                title: "个人中心 - CSON"
            },
            component: () =>
                import ('@/views/center/imformation/imformation.vue')
        },
        {
            path: '/center/myComments',
            name: 'myComments',
            meta: {
                title: "我的评论 - CSON"
            },
            component: () =>
                import ('@/views/center/myComments/comments.vue')
        },
        {
            path: '/center/certificate',
            name: 'certificate',
            meta: {
                title: "实名认证 - CSON"
            },
            component: () =>
                import ('@/views/center/certificate/certificate.vue')
        },
        {
            path: '/center/csoncertificate',
            name: 'csoncertificate',
            component: () =>
                import ('@/views/center/certificate/csoncertificate.vue')
        },
        {
            path: '/center/myResume',
            name: 'myResume',
            meta: {
                title: "简历信息 - CSON"
            },
            component: () =>
                import ('@/views/center/interpolation/myResume.vue')
        }, {
            path: '/center/accessList',
            name: 'accessList',
            meta: {
                title: "我的内推 - CSON"
            },
            component: () =>
                import ('@/views/center/interpolation/accessList.vue')
        },
        {
            path: '/center/referInvite',
            name: 'referInvite',
            meta: {
                title: "内推邀约 - CSON"
            },
            component: () =>
                import ('@/views/center/interpolation/referInvite.vue')
        },
        {
            path: '/center/mockInterview',
            name: 'mockInterview',
            meta: {
                title: "模拟面试 - CSON"
            },
            component: () =>
                import ('@/views/center/mockInterview/index.vue')
        }
    ]
}].map(a => {
    if (a.meta) {
        a.meta.authority = true
    }
    if (a.children) {
        a.children.forEach(arr => {
            if (arr.meta) {
                arr.meta.authority = true
            }

        })
    }
    return a
})