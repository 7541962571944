<template>
  <div id="app">
    <!--    登录/注册页面头部-->
    <div class="house-header" v-if="setHeader() && !noLayout()">
      <div class="cson-container">
        <div class="house-nav">
          <router-link to="/">
            <img class="logo" src="@/assets/img/logowhite.png" />
          </router-link>
        </div>
      </div>
    </div>

    <!--    其他页面头部-->
    <header-common
      v-if="!setHeader() && !setCommonHeader() && !noLayout()"
    ></header-common>
    <!-- :showFixedAct="showFixedAct" -->
    <!-- 路由跳转转场效果 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <footer-layout
      v-if="!noLayout() && !setHeader() && !setFooter()"
    ></footer-layout>
    <!-- <chat v-if="userInfo && userInfo.id && showChat"></chat> -->
  </div>
</template>

<script>
import footerLayout from "@/views/layout/children/footer.vue";
import headerCommon from "@/views/layout/children/headercommon.vue";
// import chat from "@/utils/chat/index";

import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    footerLayout,
    headerCommon,
    // chat,
  },
  data() {
    return {
      path: "",
      showFixedAct: true,
      showChat: true,
    };
  },
  created() {},
  mounted() {
    let _this = this;
    setTimeout(function () {
      _this.path = _this.$route.path;
      const token = window.localStorage.getItem("CSON_PAGE_TOKEN");
      if (token && !_this.setHeader()) {
        _this.$store.dispatch("queryUserInfo");
      }
    }, 200);
    var dev = "";
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      //设备为移动端
      dev = "mobile";
    } else {
      //设备为pc
      dev = "pc";
    }
    if (dev == "mobile") {
      window.location.href = "https://m.cspiration.com/#/";
    }
    // if (localStorage.getItem("closeActivity")) {
    //   this.showFixedAct = false;
    // } else {
    //   this.showFixedAct = true;
    // }
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
      window.scrollTo(0, 0);
      console.log(to.name);
      if (
        to.name == "video" ||
        to.name == "watchVideo" ||
        to.name == "bindEmail"
      ) {
        this.showChat = false;
      } else {
        this.showChat = true;
      }
    },
  },
  methods: {
    // 屏蔽顶部
    setHeader() {
      const noHeaderList = [
        "/register",
        "/login",
        "/findpassword",
        "/registerform",
        "/setpassword",
        "/userVerifycation",
        "/404",
        "/wechatRegister",
        "/wechatBindingEmail",
        "/wechatLogin",
        "/research",
        "/bindEmail",
        "/login_1",
      ];
      return noHeaderList.includes(this.path);
    },

    setCommonHeader() {
      const noCommonHeaderList = ["/Index", "/editExpression"];
      return noCommonHeaderList.includes(this.path);
    },
    noLayout() {
      const noLayoutList = [
        "/pay/confirm",
        "/course/video",
        "/editExpression",
        "/research",
        "/referRegister",
        "/mockInterviewerReg",
        "/referCenter",
        "/referCenter/list",
        "/referCenter/history",
        "/referCenter/myInterpolation",
        "/referCenter/information",
        "/mockCenter",
        "/mockCenter/list",
        "/mockCenter/history",
        "/mockCenter/myInterviews",
        "/mockCenter/information",
        "/livePage",
        "/answerCenter/list",
        "/answerCenter/history",
        "/answerCenter/myQuestion",
        "/answerCenter/answerQuestion",
        "/bindPhone",
      ];
      return noLayoutList.includes(this.path);
    },

    setFooter() {
      const noFooterList = [
        "/punchClock",
        "/watchVideo",
        "/company",
        "/editExpression",
        "/trends",
        "/userPage",
        "/activityFreeCourse",
        "/research",
        "/bindEmail",
      ];

      return noFooterList.includes(this.path);
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>

<style lang="scss" >
body {
  font-family: "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s;
}
::v-deep .el-image-viewer__wrapper {
  z-index: 3000 !important;
}
::v-deep .el-button--primary {
  background-color: #0075f6 !important;
}
.priceBannerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
