// 直播部分
export default [{
        path: '/live',
        name: 'live',
        component: () =>
            import ('@/views/live/Index.vue'),
        meta: {
            title: "北美直播 - 专注北美算法面试 - CSON"
        }
    },
    {
        path: '/liveRoom',
        name: 'liveRoom',
        component: () =>
            import ('@/views/live/liveRoom.vue'),
        meta: {
            title: "北美直播 - 专注北美算法面试 - CSON"
        }
    },
    {
        path: '/livePage',
        name: 'livePage',
        component: () =>
            import ('@/views/live/livePage.vue'),
        meta: {
            title: "北美直播 - 专注北美算法面试 - CSON"
        }
    },
]