// 模拟面试课程路由
export default [{
        path: '/mock/1',
        component: () =>
            import ('@/views/mock/behaviorInterview.vue'),
        name: 'behaviorInterview',
        meta: {
            title: "行为问题指导 - 专注一线大厂算法面试 - CSON"
        }
    },
    {
        path: '/mock/2',
        component: () =>
            import ('@/views/mock/mockInterview.vue'),
        name: 'mockInterview',
        meta: {
            title: "模拟面试 - 专注一线大厂算法面试 - CSON"
        }
    }, {
        path: '/mock/3',
        component: () =>
            import ('@/views/mock/resumeInterview.vue'),
        name: 'resumeGuidance',
        meta: {
            title: "简历指导 - 专注一线大厂算法面试 - CSON"
        }
    }, {
        path: '/mock/4',
        component: () =>
            import ('@/views/mock/jobPlanning.vue'),
        name: 'jobPlanning',
        meta: {
            title: "北美求职规划 - 专注一线大厂算法面试 - CSON"
        }
    }, {
        path: '/mock/5',
        component: () =>
            import ('@/views/mock/algorithmGuidance.vue'),
        name: 'algorithmGuidance',
        meta: {
            title: "算法指导 - 专注一线大厂算法面试 - CSON"
        }
    },
    {
        path: '/mockInterviewerReg',
        component: () =>
            import ('@/views/mock/mockInterviewerReg.vue'),
        name: 'mockInterviewerReg',
        meta: {
            title: "申请成为模拟面试官 - 专注一线大厂算法面试 - CSON",
            requireAuth: true
        }
    },
    {
        path: '/mockCenter/',
        component: () =>
            import ('@/views/mock/center/index.vue'),
        name: 'mockCenterIndex',
        meta: {
            title: "模拟面试中心 - 专注一线大厂算法面试 - CSON"
        },
        children: [{
            path: '/mockCenter/list',
            name: 'list',
            meta: {
                title: "面试列表 - 专注一线大厂算法面试 - CSON"
            },
            component: () =>
                import ('@/views/mock/center/list.vue')
        }, {
            path: '/mockCenter/myInterviews',
            name: 'myInterviews',
            meta: {
                title: "我的面试 - 专注一线大厂算法面试 - CSON"
            },
            component: () =>
                import ('@/views/mock/center/myInterviews.vue')
        }, {
            path: '/mockCenter/history',
            name: 'history',
            meta: {
                title: "历史面试 - 专注一线大厂算法面试 - CSON"
            },
            component: () =>
                import ('@/views/mock/center/history.vue')
        }, {
            path: '/mockCenter/information',
            name: 'information',
            meta: {
                title: "我的信息 - 专注一线大厂算法面试 - CSON"
            },
            component: () =>
                import ('@/views/mock/center/information.vue')
        }]

    },
	{
	    path: '/mock/15',
	    component: () =>
	        import ('@/views/mock/onevsone.vue'),
	    name: 'onevsone',
	    meta: {
	        title: "算法指导 - 专注一线大厂算法面试 - CSON"
	    }
	},
]