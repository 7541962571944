// 防空取参
export function getIn(data, array, initial = null) {
    let obj = Object.assign({}, data)
    for (let i = 0; i < array.length; i++) {
        if (typeof obj !== 'object' || obj === null) {
            return initial
        }
        const prop = array[i]
        obj = obj[prop]
    }
    if (obj === undefined || obj === null) {
        return initial
    }
    return obj
}

export function safeParse(str, defaultValue = null) {
    if (!str) {
        return defaultValue
    }
    if (typeof str !== 'string') {
        return str;
    }
    let result = defaultValue;
    try {
        result = JSON.parse(str);
    } catch (error) {
        console.log('[safeParse error]', error);
    }
    if (typeof result === 'string') {
        return safeParse(result);
    }
    return result;
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(email)
}

export function validPwd(str) {
    const flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
    return !flag.test(str);
}
