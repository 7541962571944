<template>
  <div class="page" style="padding: 0;">
    <!-- 轮播 已完成 -->
    <el-carousel height="600px" v-for="(item, index) in listImage" :key="index">
      <el-carousel-item>
        <div class="header-boss header-boss1">
          <div class="cs-zhuan">
            专注北美CS领域留学求职，一站式服务到FLAG世界一线公司
          </div>
          <div class="cs-8">
            8年CS领域经验 |
            突破世界级公司，从0到1入学到上岸，全程任课老师1v1服务
          </div>
          <div class="jike">
            <div @click="zixun()">立刻咨询</div>
            <div @click="toQiJian()">查看详情</div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="header-boss header-boss2">
          <div class="sde">北美SDE求职旗舰班-3个月速成去面试</div>
          <div class="kunnan">
            <div class="kunan-div">转专业困难?一50%过往学生零基础</div>
            <div class="kunan-div">自学无从下手?一 全程规划达到面试水平</div>
            <div class="kunan-div">简历没项目?一硬核，工业界项目课程</div>
          </div>
          <div class="guihua">
            求职规划/工业级项目/刷题面试——转专业零基础到在职跳槽，一站式全程服务
          </div>
          <div class="jike">
            <div @click="zixun()">立刻咨询</div>
            <div @click="toQiJian()">查看详情</div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="header-boss header-boss3">
          <div class="jiaodu">北美CS留学申请-以求职角度去看留学</div>
          <div class="famg">
            以FLAG大厂为目的：赢在留学的起跑线 | 肉身翻墙“润”来美国 |
            以求职留美为目的
          </div>
          <div class="jike jike1">
            <div @click="zixun()">立刻咨询</div>
            <div @click="detailsAction()">查看详情</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 专注北美CS领域标题 已完成 -->
    <div class="title-null" @mouseenter="removeLeft()">
      <div class="cs-title">专注北美CS领域</div>
      <div class="xuanxiao">
        <div>
          专注于北美CS留学+求职从留学申请到最后进Google、Microsoft世界一线大厂工作一站式服务。以求职角度去看留学，
        </div>
        <div>
          在留学选校阶段就做好去北美工作的准备。无论是转专业零基础，转行，以我们8年经验每种情况怎么顺利进入北美巨头公司。
        </div>
      </div>
    </div>
    <!-- 专注北美CS领域图片动画 -->
    <div class="qiuzhi" v-show="csMain">
      <div class="qiuzhi-left" @mouseenter="detailsLeft()">
        <div class="qiuzhi-title">北美CS留学</div>
        <div class="qiuzhi-content">专注计算机专业留学服务</div>
        <div class="qiuzhi-btn">查看详情 ></div>
      </div>
      <div class="qiuzhi-right" @mouseenter="detailsRight()">
        <div class="qiuzhi-title">北美CS求职</div>
        <div class="qiuzhi-content">以FLAG留美求职为目标的解决方案</div>
        <div class="qiuzhi-btn" @click="leftDetails()">查看详情 ></div>
      </div>
    </div>

    <div
      class="qiuzhi1 animate__animated animate__fadeInLeft"
      v-show="leftShow"
    >
      <div class="qiuzhi1-box">
        <div class="liuxue">北美CS留学</div>
        <div class="jisuanji">专注计算机专业留学服务</div>
        <div class="chakan"">查看详情 ></div>
        <div class="xiangce">
          <div class="xiangce-image">
<!--            注释掉了鼠标悬停事件，后期可以自己解除注释改回来-->
            <div
              class="xiangce-image-content xiangce1"
              @mouseenter="imageAction(0)"
            >
              <div class="zhuanye">专业选校分析</div>
            </div>
          </div>
          <div class="xiangce-image">
<!--            <div-->
<!--                class="xiangce-image-content xiangce2"-->
<!--                @mouseenter="imageAction(2)"-->
<!--                @click="kecheng"-->
<!--            >-->
            <div
              class="xiangce-image-content xiangce2"
              @mouseenter="imageAction(1)">
              <div class="zhuanye">一站式留学方案</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="qiuzhi2 animate__animated animate__fadeInRight"
      v-show="rightShow"
    >
      <div class="qiuzhi1-box qiuzhi2-box">
        <div class="liuxue">北美CS求职</div>
        <div class="jisuanji">专注计算机专业求职服务</div>
        <div class="chakan" @click="leftDetails()">查看详情 ></div>
        <div class="xiangce">
          <div class="xiangce-image">
            <div
              class="xiangce-image-content xiangce3"
              @mouseenter="imageAction(2)"
            >
              <div class="zhuanye">世界一线公司揭秘</div>
            </div>
          </div>
          <div class="xiangce-image">
            <div
              class="xiangce-image-content xiangce4"
              @mouseenter="imageAction(3)"
            >
              <div class="zhuanye">求职辅导课程</div>
            </div>
          </div>
          <div class="xiangce-image">
            <div
              class="xiangce-image-content xiangce5"
              @mouseenter="imageAction(4)"
            >
              <div class="zhuanye">一站式求职方案</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 定位页面 -->
    <div class="position">
      <div class="position-box">
        <div class="yizhanshi">北美CS求职——一站式解决方案</div>
        <div class="quancheng">全程1v1规划监督，任课老师亲自指导教学</div>
        <div class="zhengdui">
          <div>针对个人目标，搜索公司目标题库</div>
          <div>进行针对性准备训练</div>
        </div>
        <div class="dajian">
          <div class="dajian-left">
            <div>搭建完整的</div>
            <div>"算法知识体系"和"设计知识体系"</div>
          </div>
          <div class="dajian-right">
            <div>
              <div>大厂面试流程全知晓</div>
              <div>全真模拟面试</div>
            </div>
          </div>
        </div>
        <div class="yiuduiyi">
          <div class="yiuduiyi-left">
            <div>一对一专业求职咨询</div>
            <div>十年经验老师为您指路</div>
          </div>
          <div class="yiuduiyi-right">
            <div>
              <div>恭喜您</div>
              <div>收获理想offer!</div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-list">
        <div class="position-list-div">
          <div class="position-list-div-left position-list-div-left1"></div>
          <div class="position-list-div-right">
            <div class="mianShi1">面试真题全收纳</div>
            <div class="mianShi1-bottom">
              <div class="mianShi-top mianShi2">持续六年面试真题研究分析</div>
              <div class="mianShi2">一线名师&大厂高管联合打造</div>
              <div class="mianShi2">
                缔造IT界的<font style="color: #0075f6">“五年高考三年模拟”</font>
              </div>
            </div>
          </div>
        </div>
        <div class="position-list-div">
          <div class="position-list-div-left position-list-div-left2"></div>
          <div class="position-list-div-right">
            <div class="mianShi1">刷题高效省时间</div>
            <div class="mianShi1-bottom">
              <div class="mianShi-top mianShi2">过往学生经历验证</div>
              <div class="mianShi2">
                在CSON<font style="color: #0075f6">3个月</font>的学习效果
              </div>
              <div class="mianShi2">
                独自刷题<font style="color: #0075f6">半年</font>
              </div>
            </div>
          </div>
        </div>
        <div class="position-list-div">
          <div class="position-list-div-left position-list-div-left3"></div>
          <div class="position-list-div-right">
            <div class="mianShi1">老师答疑更放心</div>
            <div class="mianShi1-bottom">
              <div class="mianShi-top mianShi2">保姆式监督催学让你不拖延</div>
              <div class="mianShi2">全程名师辅导课程答疑</div>
              <div class="mianShi2">
                给你面经题的<font style="color: #0075f6">正确最优解</font>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 市场消息先我们一步 -->
    <div class="title-null">
      <div class="cs-title">市场消息我们先人一步</div>
      <div class="xuanxiao">你想知道的我们都有，一起赢在起跑线上</div>
    </div>
    <!-- 市场消息先我们一步图片部分 -->
    <div class="message-content">
      <div
        class="message-boss message-left"
        @mouseenter="zuixingMouse(0)"
        @click="kecheng()"
      >
        <div class="mask-content">
          <div
            class="zuixing"
            :class="{
              'animate__animated animate__fadeInUp':
                zuixingtitle2 && zuixingIndex == 0,
            }"
          >
            最新大厂招聘信息
          </div>
          <div
            class="zuixing1 animate__animated animate__fadeInLeft"
            v-show="xingzi && zuixingIndex == 0"
          >
            薪资待遇全知晓
          </div>
          <div
            class="zuixing1 zuixing3 animate__animated animate__fadeInLeft"
            v-show="xingzi && zuixingIndex == 0"
          >
            ->
          </div>
        </div>
        <div class="mask-show" v-if="xingzi && zuixingIndex == 0"></div>
      </div>
      <div
        class="message-boss message-center"
        @mouseenter="zuixingMouse(1)"
        @click="kecheng()"
      >
        <div class="mask-content">
          <div
            class="zuixing"
            :class="{
              'animate__animated animate__fadeInUp':
                zuixingtitle2 && zuixingIndex == 1,
            }"
          >
            北美公司内部揭秘
          </div>

          <div
            class="zuixing1 animate__animated animate__fadeInLeft"
            v-show="xingzi && zuixingIndex == 1"
          >
            薪资待遇全知晓
          </div>
          <div
            class="zuixing1 zuixing3 animate__animated animate__fadeInLeft"
            v-show="xingzi && zuixingIndex == 1"
          >
            ->
          </div>
        </div>
        <div class="mask-show" v-if="xingzi && zuixingIndex == 1"></div>
      </div>
      <div
        class="message-boss message-right"
        @mouseenter="zuixingMouse(2)"
        @click="kecheng()"
      >
        <div class="mask-content">
          <div
            class="zuixing"
            :class="{
              'animate__animated animate__fadeInUp':
                zuixingtitle2 && zuixingIndex == 2,
            }"
          >
            LeetCode分类顺序表
          </div>
          <div
            class="zuixing1 animate__animated animate__fadeInLeft"
            v-show="xingzi && zuixingIndex == 2"
          >
            LeetCode分类顺序表
          </div>
          <div
            class="zuixing1 zuixing3 animate__animated animate__fadeInLeft"
            v-show="xingzi && zuixingIndex == 2"
          >
            ->
          </div>
        </div>
        <div class="mask-show" v-if="xingzi && zuixingIndex == 2"></div>
      </div>
    </div>

    <!-- 成功案例 -->
    <div class="success-job">
      <div class="success-echarts">
        <div class="success-echarts-box">
          <div class="success-title">成功案例</div>
          <div class="guangao">数据是最好的广告</div>
          <div class="guangao-bg">
            <div class="guanggao-biaoge" :id="scrollId">
              <div
                class="biaoge-box"
                v-for="(item, index) in guanggaoList"
                :key="index"
              >
                <div class="biaoge-content">{{ item.name }}</div>
                <div class="biaoge-content">{{ item.title }}</div>
                <div class="biaoge-content"><img :src="item.index" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户声音 -->
    <div class="title-null">
      <div class="cs-title">客户声音?</div>
      <div class="xuanxiao">不同阶段客户给予我们的支持与反馈</div>
    </div>
    <div class="shengying">
      <div class="anli-top">
        <div class="anli-top-title">初入美国</div>
        <div class="anli-top-title">寻找实习</div>
        <div class="anli-top-title">校招在即</div>
        <div class="anli-top-title">工作人士</div>
      </div>
      <div class="anli-bottom">
        <div
          class="anli-top-title title1"
          v-for="(item, index) in anliList"
          :key="index"
        >
          <div class="title-box">
            <div class="bottom-name" :style="{ color: item.color }">
              {{ item.name }}
            </div>
            <div class="bottom-name" :style="{ color: item.color }">
              {{ item.zhiwei }}
            </div>
            <div class="gaozhong-content" :style="{ color: item.color }">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于CSON -->
    <div class="footer-list">
      <div class="cson">关于CSON</div>
      <div class="cson-content">
        <div>
          CSON（Cspiration），专注于北美CS领域留学和求职，FLAG级别互联网公司面试，致力于让更多的国内学子申请到理想的大学
        </div>
        <div>
          并在IT领域内帮助中国人找到好工作，让更多海内外国人受益。导师3000+实战刷题经验，独特的体系化课程。让北美和国内的转专业，留学生，跳槽者更轻松的申请和学习
        </div>
        <div>帮助更多CSer圆梦FMAG！</div>
      </div>
    </div>
    <!-- 立刻咨询详情 -->
    <helpClass
      :visible="zixunShow"
      @setHelpVisible="(val) => (zixunShow = val)"
    ></helpClass>
	<leetDialog></leetDialog>
  </div>
</template>

<script>
import turn from "vue-flip-page";
import helpClass from "../views/ban/components/help.vue";
import leetDialog from "@/components/leetDialog.vue";

export default {
  components: { turn, helpClass ,leetDialog},

  data() {
    return {
      leftShow: false,
      rightShow: false,
      createadShow: true,
      scrollTimer: null, // 滚动定时器
      pauseTimer: null, // 暂停定时器
      scrollId: "scrollId", // 滚动容器id
      scrollDirection: "down", // 滚动方向 up向上 down向下
      centerShow: false,
      currentIndex: 0,
      footersenterShow: true,
      footersenterNone: false,
      footerTopShow: false,
      footerCurrentIndex: 0,
      listImage: [
        {
          title: "",
        },
        // {
        //   title:''
        // },
        // {
        //   title:''
        // }
      ],
      detailsLeftShow: false,
      csMain: true,
      rightShow: false,
      imageIndex: 0,
      xingzi: false,
      zuixingtitle: true,
      zuixingIndex: 0,
      zuixingtitle2: false,
      guanggaoList: [
        {
          name: "Duoduo Li",
          title: "USC | CS",
          index: require("../assets/index/image/list/Google.png"),
        },
        {
          name: "Jie Chen",
          title: "4 years experience",
          index: require("../assets/index/image/list/amazon.png"),
        },
        {
          name: "Heng Chu",
          title: "NEU | CS",
          index: require("../assets/index/image/list/LinkedIn.png"),
        },
        {
          name: "Aiya Shi",
          title: "UCL | CS",
          index: require("../assets/index/image/list/cisco.png"),
        },
        {
          name: "Quanda zeng",
          title: "2 years experience",
          index: require("../assets/index/image/list/uber.png"),
        },
        {
          name: "Yu Lin",
          title: "CMU | CS",
          index: require("../assets/index/image/list/meta.png"),
        },
        {
          name: "Lu Bai",
          title: "NEU | IS",
          index: require("../assets/index/image/list/Verizon.png"),
        },
        {
          name: "Jing Zhao",
          title: "3 years experience",
          index: require("../assets/index/image/list/Walmart.png"),
        },
        {
          name: "Xiangjun Wei",
          title: "UTD | EE",
          index: require("../assets/index/image/list/uber.png"),
        },
        {
          name: "Lianwei Shi",
          title: "SIT | CS",
          index: require("../assets/index/image/list/Intel.png"),
        },
        {
          name: "Hao Wu",
          title: "8 years experience",
          index: require("../assets/index/image/list/Airbnb.png"),
        },
        {
          name: "Hu Chen",
          title: "UCSD | DS",
          index: require("../assets/index/image/list/uber.png"),
        },
        {
          name: "Chuyang Cheng",
          title: "UTA",
          index: require("../assets/index/image/list/amazon.png"),
        },
        {
          name: "Haowen Li",
          title: "3 years experience",
          index: require("../assets/index/image/list/Google.png"),
        },
        {
          name: "Lifang Zhou",
          title: "1 years experience",
          index: require("../assets/index/image/list/Google.png"),
        },
      ],
      anliList: [
        {
          name: "张同学",
          zhiwei: "22 | 211本美硕",
          content:
            "高中毕业入学美本NYU，入学后发现当年秋招就可以参加第二年实习，而自己什么基础都没有，需要学习和准备哪些内容也无从下手，通过老师详细规划，开始沉下心来准备简历，算法等一系列事宜，终于在大二的秋招斩获Amazon的Intern岗位",
          id: "",
          color: "black",
        },
        {
          name: "陈同学",
          zhiwei: "20 | 美本",
          content:
            "CMU出身的她一直是一位学霸，学校的GPA也一直很高，但研究生阶段都用来学学校课程，对于业界知之甚少，第一次投递的时候准备不足，工业级的project，算法都没有准备完善，导致一直拿不到面试机会，而仅有的一次面试也因为算法挂了，痛定思痛的陈同学跟着我们开始做全面的准备，4个月的全力冲刺，终于帮助 她斩获Google，Meta，Microsoft三家offer",
          id: "",
          color: "white",
        },
        {
          name: "李同学",
          zhiwei: "25 | 211本美硕",
          content:
            "玩心比较重的李同学还有一年即将毕业，面临毕业即失业的窘境，当下属于三无选手（无项目&无实习&无刷题）的他终于意识到自己 想要上岸需要努力了，跟着老师的规划，做了三个工业级Project，大大完善了简历，废寝忘食的刷题，最终400题刷了3遍，终于蜕变，在毕业前完成逆袭成功上岸Google",
          id: "",
          color: "black",
        },
        {
          name: "陈同学",
          zhiwei: "31 | 211本美硕",
          content:
            "国内大厂经历5年的陈同学厌倦了996，想要到美国大厂工作，我们帮他规划了“润”的最佳路径，申请了1.5年制的项目，在落地之前把算法和系统设计进行了体系化的特训，一到美国就参加招聘，非常轻松的斩获了多家offer，最终选择了包裹最大的Meta，仅仅一年半时间，就完成了留学求职上岸美国大厂的既定目标",
          id: "",
          color: "white",
        },
      ],
      zixunShow: false,
    };
  },
  destroyed() {
    // 清理定时器
    clearTimeout(this.pauseTimer);
    this.pauseTimer = null;
    clearInterval(this.scrollTimer);
    this.scrollTimer = null;
    // 清理点击监听
    window.document.removeEventListener("click", this.pauseScroll);
  },
  mounted() {
    this.dataCompleteFun();
  },
  methods: {
    leftDetails(){
      this.$router.push("/course/7");
    },
    zixun() {
      this.zixunShow = true;
    },
    handleClose() {
      this.zixunShow = false;
    },
    detailsAction() {
      this.$router.push("/course");
    },
    toQiJian() {
      this.$router.push("/course/7");
    },
    kecheng() {
      this.$router.push("/course");
    },
    // 数据加载完成方法
    dataCompleteFun() {
      // 开启滚动
      this.autoScroll();
    },
    autoScroll() {
      const scrollHeight = document.getElementById(this.scrollId).scrollHeight;
      const clientHeight = document.getElementById(this.scrollId).clientHeight;
      const scroll = scrollHeight - clientHeight;
      // 滚动长度为0
      if (scroll === 0) {
        return;
      }
      // 触发滚动方法
      this.scrollFun();
      // 去除点击监听
      window.document.removeEventListener("click", this.pauseScroll);
      // 重设点击监听
      window.document.addEventListener("click", this.pauseScroll, false);
    },
    pauseScroll() {
      // 定时器不为空
      if (this.scrollTimer) {
        // 清除定时器
        clearInterval(this.scrollTimer);
        this.scrollTimer = null;
        // 一秒钟后重新开始定时器
        this.pauseTimer = setTimeout(() => {
          this.scrollFun();
        }, 1000);
      }
    },
    imageSuofang(data) {
      this.centerShow = true;
      this.currentIndex = data;
    },
    scrollFun() {
      // 如果定时器存在
      if (this.scrollTimer) {
        // 则先清除
        clearInterval(this.scrollTimer);
        this.scrollTimer = null;
      }
      this.scrollTimer = setInterval(() => {
        const scrollHeight = document.getElementById(
          this.scrollId
        ).scrollHeight;
        const clientHeight = document.getElementById(
          this.scrollId
        ).clientHeight;
        const scroll = scrollHeight - clientHeight;
        // 获取当前滚动条距离顶部高度
        const scrollTop = document.getElementById(this.scrollId).scrollTop;
        // 向下滚动
        if (this.scrollDirection === "down") {
          const temp = scrollTop + 5;
          document.getElementById(this.scrollId).scrollTop = temp; // 滚动
          // 距离顶部高度  大于等于 滚动长度
          if (scroll <= temp) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer);
            this.scrollTimer = null;
            // 改变方向
            this.scrollDirection = "up";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun();
            }, 500);
          }
          // 向上滚动
        } else if (this.scrollDirection === "up") {
          const temp = scrollTop - 5;
          document.getElementById(this.scrollId).scrollTop = temp; // 滚动
          // 距离顶部高度  小于等于 0
          if (temp <= 0) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer);
            this.scrollTimer = null;
            // 改变方向
            this.scrollDirection = "down";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun();
            }, 500);
          }
        }
      }, 100);
    },
    zuixingMouse(index) {
      this.xingzi = true;
      this.zuixingtitle = false;
      this.zuixingtitle2 = true;
      this.zuixingIndex = index;
    },

    imageAction(index) {
      this.imageIndex = index;
    },
    removeLeft() {
      this.csMain = true;
      this.leftShow = false;
      this.rightShow = false;
    },
    detailsLeft() {
      this.csMain = false;
      this.leftShow = true;
      this.rightShow = false;
    },
    detailsRight() {
      this.csMain = false;
      this.leftShow = false;
      this.rightShow = true;
    },
  },
};
</script>

<style scoped>
.mask-content {
  position: relative;
  z-index: 99;
}

.mask-show {
  width: 380px;
  height: 280px;
  position: absolute;
  background: #001a53;
  opacity: 0.8;
}

.mianShi1-bottom {
  margin-top: 35px;
}

/*.xiangce-image-content:hover {*/
/*  cursor: pointer;*/
/*}*/

.liuxue {
  font-size: 56px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 19px;
}

.jisuanji {
  font-size: 30px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 19px;
}

.chakan {
  width: 221px;
  height: 71px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  margin-top: 32px;
  line-height: 71px;
}

.xiangce-image {
  width: 380px;
  height: 200px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 20px;
  overflow: hidden;
}

.xiangce-image-content {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  color: white;
}

/*.xiangce-image-content:hover {*/
/*  cursor: pointer;*/
/*  transform: scale(1.5);*/
/*}*/

.xiangce-image {
  margin-right: 42px !important;
}

.xiangce1 {
  background: url("../assets/index/image/chengbao2X.png");
  background-size: 100% 100%;
}

.zhuanye {
  font-size: 24px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  position: fixed;
  bottom: 40px;
}

.xiangce2 {
  background: url("../assets/index/image/fangan2X.png");
  background-size: 100% 100%;
}

.xiangce3 {
  background: url("../assets/index/image/yixiang2X.png");
  background-size: 100% 100%;
}

.xiangce4 {
  background: url("../assets/index/image/fudao2X.png");
  background-size: 100% 100%;
}

.xiangce5 {
  background: url("../assets/index/image/qiuzhi2X.png");
  background-size: 100% 100%;
}

.xiangce {
  display: inline-flex;
  margin-top: 37px;
}

.qiuzhi1-box {
  margin-left: 209px;
  margin-top: 19px;
}

.jiaodu {
  margin-top: 321px;
  margin-left: 355px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 400;
}

.famg {
  margin-left: 355px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 29px;
}

.jike1 {
  margin-left: 355px !important;
  margin-top: 59px;
}

.sde {
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  text-align: center;
  margin-top: 226px;
}

.kunnan {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  text-align: center;
  margin-top: 34px;
  display: inline-flex;
  justify-content: center;
}

.header-boss {
  width: 100%;
  color: white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.kunan-div {
  width: 350px;
  height: 33px;
  border: 1px solid #ffffff;
  border-radius: 17px;
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.guihua {
  margin-top: 51px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.cs-zhuan {
  margin-top: 236px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.cs-8 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.jike {
  display: inline-flex;
  justify-content: center;
  margin-top: 58px;
}

.jike div {
  width: 140px;
  height: 50px;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.jike div:nth-of-type(1) {
  background: white;
  color: #0075f6;
}

.jike div:nth-of-type(2) {
  background: #0075f6;
  color: white;
  margin-left: 32px;
}

.page {
  width: 100%;
  background: #f0f2f5;
}

.scaleMouse {
  transform: scale(1.2);
}

.header-boss {
  height: 600px;
  width: 100%;
  background: url("../assets/index/image/banner1@2X.png");
  background-size: 100% 100%;
}

.header-boss1 {
  background: url("../assets/index/image/banner1@2X.png");
  background-size: 100% 100%;
}

.header-boss2 {
  background: url("../assets/index/image/banner2@2X.png");
  background-size: 100% 100%;
}

.header-boss3 {
  background: url("../assets/index/image/banner3@2X.png");
  background-size: 100% 100%;
  display: inline-block;
}

.cs-header {
  color: white;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 400;
  padding-top: 236px;
  width: 100%;
  text-align: center;
}

.cs-8 {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 27px;
  font-size: 16px;
  color: #ffffff;
}

.title-null {
  background: #f0f2f5;
}

.cs-title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  text-align: center;
  width: 100%;
  padding-top: 41px;
}

.xuanxiao {
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 19px;
  margin-bottom: 47px;
}

.qiuzhi {
  width: 100%;
  height: 550px;
  display: inline-flex;
  background: url("../assets/index/image/1.png");
  background-size: 100% 100%;
}

.qiuzhi1 {
  width: 100%;
  height: 550px;
  display: inline-flex;
  background: url("../assets/index/image/alAll2X.png");
  background-size: 100% 100%;
}

.qiuzhi2 {
  width: 100%;
  height: 550px;
  display: inline-flex;
  background: url("../assets/index/image/arAll2X.png");
  background-size: 100% 100%;
}

.qiuzhi-left {
  width: 50%;
  height: 100%;
  margin-top: 195px;
  text-align: center;
}

.qiuzhi-right {
  width: 50%;
  height: 100%;
  margin-top: 195px;
  text-align: center;
}

.qiuzhi-title {
  font-size: 56px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
}

.qiuzhi-content {
  font-size: 30px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 29px;
}

.qiuzhi-btn {
  width: 220px;
  height: 70px;
  background: #0075f6;
  border-radius: 20px;
  display: inline-block;
  font-size: 24px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 85px;
}

.position {
  height: 920px;
  width: 100%;
  background: url("../assets/index/image/abosolut2X.png");
  background-size: 100% 100%;
}

.message-content {
  height: 280px;
  width: 100%;
  margin-top: 25px;
  display: inline-flex;
  justify-content: center;
}

.message-boss {
  width: 380px;
  height: 100%;
  margin-left: 35px;
}

.message-boss:nth-of-type(1) {
  margin-left: 0 !important;
}

.message-left {
  background: url("../assets/index/image/dianzan2X.png");
  background-size: 100% 100%;
}

.message-center {
  background: url("../assets/index/image/left2x.png");
  background-size: 100% 100%;
}

.message-right {
  background: url("../assets/index/image/team2X.png");
  background-size: 100% 100%;
}

.success-job {
  height: 800px;
  width: 100%;
  margin-top: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: url("../assets/index/image/bg2X.png");
  background-size: 100% 100%;
}

.success-echarts {
  width: 1142px;
  height: 537px;
  background: url("../assets/index/image/cennterzhibiao.png");
  background-size: 100% 100%;
  margin-top: 70px;
}

.shengying {
  background: url("../assets/index/image/footerList2X.png");
  background-size: 100% 100%;
  height: 421px;
  width: 1505px;
  margin: 0 auto;
}

.footer-list {
  height: 300px;
  background: url("../assets/index/image/yejing2X.png");
  background-size: 100% 100%;
  margin-top: 37px;
  text-align: center;
}

.cson {
  padding-top: 85px;
  font-size: 36px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #000000;
}

.cson-content {
  margin-top: 27px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #000000;
}

.qiuzhi2-box {
  margin-left: 495px !important;
}

.chakan {
  text-align: center;
  font-size: 24px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.position-box {
}

.yizhanshi {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  padding-top: 50px;
  width: 100%;
  text-align: center;
}

.quancheng {
  margin-top: 19px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.zhengdui {
  margin-top: 30px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.position-box {
  width: 1226px;
  margin: 0 auto;
}

.dajian {
  width: 100%;
  margin-top: 67px;
  display: inline-flex;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.dajian-left {
  width: 26%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.dajian-right {
  width: 59%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.yiuduiyi {
  width: 100%;
  margin-top: 145px;
  display: inline-flex;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.yiuduiyi-left {
  width: 16%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.yiuduiyi-right {
  width: 76%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.position-list {
  margin-top: 44px;
  display: inline-flex;
  height: 200px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.position-list-div {
  width: 480px;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin-right: 32px;
  display: inline-flex;
  padding: 5px;
}

.position-list-div-left {
  width: 180px;
  height: 100%;
  background: #0075f6;
  border-radius: 8px;
}

.position-list-div-right {
  margin-left: 10px;
  width: 300px;
  height: 100%;
  overflow: auto;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.mianShi1 {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: bold;
  color: #000000;
}

.mianShi2 {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}

.position-list-div-left1 {
  background: url("../assets/index/image/phone2X.png");
  background-size: 100% 100%;
}

.position-list-div-left2 {
  background: url("../assets/index/image/jianpan.png");
  background-size: 100% 100%;
}

.position-list-div-left3 {
  background: url("../assets/index/image/jianpan.png");
  background-size: 100% 100%;
}

.message-boss {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
}

.jike:hover{
  cursor: pointer;
}
.zuixing {
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 14px;
  margin-left: 32px;
}

.zuixing1 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-left: 32px;
}

.zuixing3 {
  margin-bottom: 14px;
}

.success-title {
  font-size: 36px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: relative;
  top: -65px;
}

.guangao {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  text-align: center;
  position: relative;
  top: -55px;
}

.success-echarts-box {
  width: 100%;
  height: 100%;
  margin-top: -54px;
}

.guangao-bg {
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
}

.guanggao-biaoge {
  width: 40%;
  height: 85%;
  margin: 0 auto;
  overflow: auto;
}

.biaoge-box {
  display: inline-flex;
  width: 100%;
}

.biaoge-content {
  width: 33%;
  height: 50px;
  flex-grow: 1;
}

.anli-top {
  width: 100%;
  display: inline-flex;
  height: 60px;
}

.anli-top-title {
  width: 25%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #0075f6;
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
}

.anli-bottom {
  margin-top: 87px;
  height: 250px;
  overflow: auto;
  margin-bottom: 50px;
}

.title-box {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  text-align: center;
}

.bottom-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.gaozhong-content {
  font-size: 14px;
  color: #000000;
}
</style>