// leetcode部分
export default [{
        path: '/leetcodeClassification',
        name: "leetcodeClassification",
        component: () =>
            import ('@/views/leetcode/index.vue'),
        meta: {
            title: "leetcode channel - 专注北美算法面试 - CSON",
        }
    }, {
        path: '/leetcodeClassification/discussion',
        name: "discussion",
        component: () =>
            import ('@/views/leetcode/discussion.vue'),
        meta: {
            title: "讨论区 - 专注北美算法面试 - CSON",
        }
    },
    {
        path: '/leetcodeClassification/codeDetail',
        name: "codeDetail",
        component: () =>
            import ('@/views/leetcode/codeDetail.vue'),
        meta: {
            title: "详情 - 专注北美算法面试 - CSON",
        }
    },
    {
        path: '/leetcodeClassification/preferIdea',
        name: "preferIdea",
        component: () =>
            import ('@/views/leetcode/preferIdea.vue'),
        meta: {
            title: "提供思路 - 专注北美算法面试 - CSON",
            requireAuth: true
        }
    }, {
        path: '/leetcodeClassification/preferResolution',
        name: "preferResolution",
        component: () =>
            import ('@/views/leetcode/preferResolution.vue'),
        meta: {
            title: "提供解法 - 专注北美算法面试 - CSON",
            requireAuth: true
        }
    },
    {
        path: '/leetcodeRewards',
        name: "leetcodeRewards",
        component: () =>
            import ('@/views/leetcode/leetcodeRewards.vue'),
        meta: {
            title: "LeetCode 思路解法有偿征集 - 专注北美算法面试 - CSON"
        }
    }
]