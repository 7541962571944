 /*
 * @description: 公用方法封装
 * @Author: along
 * @Date: 2020-04-24
 * @Last Modified by: along
 * @Last Modified time: 2020-04-24
 */

/**
 * 日期格式化
 * @param date Date 目标日期对象/String 日期对应字符串/ Number: 时间戳(精确到毫秒)
 *        String fmt 指定格式字符串月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q)可以用 1-2 个占位符、年(y)可以用 1-4 个占位符、毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @return Boolean
 */
export function dateFormat(dateTmp, fmtTmp) {
    let fmt = fmtTmp;
    let date = dateTmp;

    if (!fmt) {
        fmt = 'yyyy.MM.dd';
    }
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    let week = {
        "0": "日",
        "1": "一",
        "2": "二",
        "3": "三",
        "4": "四",
        "5": "五",
        "6": "六"
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "星期" : "周") : "") + week[date.getDay() + ""]);
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

/**
 * 生成随机字符串
 */
export function generateRandomStr() {
    return Math.random().toString(36).substr(2);
}

/**
 * 时期转换为时间戳，日期格式:2019-08-09
 */
export function setDate (no) {
    let year = new Date(no).getFullYear(),
        month = new Date(no).getMonth()+1,
        day = new Date(no).getDate();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    let data = year + '-' + month + '-' + day;
    let time = new Date(data).getTime();

    return time / 1000;
}

/**
 * 获取标准时间戳时间格式2019-08-28 00:00:00
 */
export function getDate (no) {
    let year = new Date(no).getFullYear(),
        month = new Date(no).getMonth()+1,
        day = new Date(no).getDate(),
        hous = new Date(no).getHours(),
        minus = new Date(no).getMinutes(),
        secon = new Date(no).getSeconds();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    hous = hous < 10 ? '0' + hous : hous;
    minus = minus < 10 ? '0' + minus : minus;
    secon = secon < 10 ? '0' + secon : secon;
    let date = year + '-' + month + '-' + day + ' ' + hous + ':' + minus + ':' + secon;
    let time = new Date(date).getTime();

    return time / 1000;
}

/**
 * 剩余日期
 */
export function restDate (temp) {
    let second = temp / 1000;

    if(second > 0) {
        const days = Math.floor(second / 86400);
        const hours = Math.floor((second % 86400) / 3600);
        const minutes = Math.floor(((second % 86400) % 3600) / 60);
        const seconds = Math.floor(((second % 86400) % 3600) % 60);
        const forMatDate = {
            days: days,
            hours: hours < 10 ? ('0' + hours) : hours,
            minutes: minutes < 10 ? ('0' + minutes) : minutes,
            seconds: seconds < 10 ? ('0' + seconds) : seconds
        };
        const __days = forMatDate.days ? forMatDate.days + '天' : '';

        return __days + forMatDate.hours + ':' + forMatDate.minutes + ':' + forMatDate.seconds;
    } else {
        return '已过期';
    }
}

/**
 * deep  copy
 * @param source
 * @return {*}
 */
export function deepClone(source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone');
    }
    const targetObj = source.constructor === Array ? [] : {};

    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys]);
        } else {
            targetObj[keys] = source[keys];
        }
    });
    return targetObj;
}

/**
 * 格式化文件大小
 * @param fileSize
 * @param idx
 * @returns {string|*}
 */
export function formatFileSize(fileSize, idx = 0) {
    if(!fileSize) {
        return '0B';
    }
    const units = ["B", "KB", "MB", "GB"];

    if (fileSize < 1024 || idx === units.length - 1) {
        return fileSize.toFixed(2) + units[idx];
    }
    // eslint-disable-next-line no-param-reassign
    return formatFileSize(fileSize / 1024, ++idx);
}
