 /*
 * @description: 公用方法封装
 * @Author: along
 * @Date: 2020-04-24
 * @Last Modified by: along
 * @Last Modified time: 2020-04-24
 */

import { dateFormat, restDate } from './until';

function dateFormatter(date, formatter) {
    if (date) {
        return dateFormat(date, formatter);
    } else {
        return '--';
    }
}

function restFormatter (second) {
    if(second) {
        return restDate(second);
    } else {
        return '--';
    }
}

export {
    dateFormatter,
    restFormatter
};