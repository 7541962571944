// 课程
export default [ //{
    //         path: '/course/1', //100天100题
    //         component: () =>
    //             import ('@/views/ban/leetcode-100.vue'),
    //         name: 'course1',
    //         meta: {
    //             title: "10天LeetCode经典100题 - 专注一线大厂算法面试 -CSON"
    //         },
    //         children: []
    //     },
    {
        path: '/course/2', //LeetCode题目视频讲解
        component: () =>
            import ('@/views/ban/2-LeetCodeVideoCourse.vue'),
        name: 'course2',
        meta: {
            title: "LeetCode题目视频讲解 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },
    {
        path: '/course/4', //求职面试算法班
        component: () =>
            import ('@/views/ban/4-AlgorithmCourse.vue'),
        name: 'course4',
        meta: {
            title: "求职面试算法班 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },
    {
        path: '/course/3', //北美SDE算法速成班
        component: () =>
            import ('@/views/ban/3-AlgorithmCrashCourse.vue'),
        name: 'course3',
        meta: {
            title: "北美SDE算法速成班 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },
    {
        path: '/course/5', //求职面试刷题班
        component: () =>
            import ('@/views/ban/5-ExerciseCourse.vue'),
        name: 'course5',
        meta: {
            title: "求职面试刷题班 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },

    // {
    //     path: '/course/9', // 北美SDC算法突击班
    //     component: () =>
    //         import ('@/views/ban/beimei-sde.vue'),
    //     name: 'course9',
    //     meta: {
    //         title: "北美SDC算法突击班 - 专注一线大厂算法面试 -CSON"
    //     },
    //     children: []
    // },
    {
        path: '/course/6', //北美SDE求职速成班
        component: () =>
            import ('@/views/ban/6-UsCrashCourse.vue'),
        name: 'course6',
        meta: {
            title: "北美SDE求职速成班 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },
    {
        path: '/course/7', //北美SDE求职旗舰版
        component: () =>
            import ('@/views/ban/7-UsVIPCourse.vue'),
        name: 'cours7',
        meta: {
            title: "北美SDE求职旗舰班 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    }, {
        path: '/course/8', // 面向对象设计OOD
        component: () =>
            import ('@/views/ban/8-ObjectDesignCourse.vue'),
        name: 'course1',
        meta: {
            title: "面向对象设计OOD - 专注一线大厂算法面试 -CSON"
        },
        children: []
    }, {
        path: '/course/9',
        component: () =>
            import('@/views/ban/9-UsContractCourse.vue'),
        name: 'course9',
        meta: {
            title: "北美SDE求职合约班 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },{
        path: '/course/10', //北美SDE求职旗舰版试听
        component: () =>
            import ('@/views/ban/10-UsVIPCourseTest.vue'),
        name: 'cours7',
        meta: {
            title: "北美SDE求职旗舰班试听 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },{
        path: '/course/12', //北美SDE项⽬速成班
        component: () =>
            import ('@/views/ban/12-UsVIPProject.vue'),
        name: 'cours12',
        meta: {
            title: "北美SDE项⽬速成班 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },{
        path: '/course/11', //北美CS求职课 
        component: () =>
            import ('@/views/ban/11-CsJob.vue'),
        name: 'cours11',
        meta: {
            title: "北美CS求职课  - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },{
        path: '/course/13', //北美CS留学申请
        component: () =>
            import ('@/views/ban/13-LeetCodeVideoCourseFree.vue'),
        name: 'cours13',
        meta: {
            title: "LeetCode题目视频讲解 - 专注一线大厂算法面试 -CSON"
        },
        children: []
    },


].map(a => {
    if (a.meta) {
        a.meta.authority = true
    }
    if (a.children) {
        a.children.forEach(arr => {
            if (arr.meta) {
                arr.meta.authority = true
            }

        })
    }
    return a
})